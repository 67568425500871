/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// Loader component
import { ThreeDots } from 'react-loader-spinner';

// Authentication
import { auth, firestore } from "./../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";

import {
  SET_ACTIVE_ENTITY,
  SET_PERSONA,
  SET_QUERY
} from 'store/actions';

import {
  chartOptions,
  parseOptions
} from "variables/charts.js";
import { arrayOf } from 'prop-types';

// Persona (master)
const masterPersona = `
`;

// Get last 7 days
let dates = [];
let values = [];
for (let i = 0; i < 7; i++) {
    // Setup default
    dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    values.push(0);
}

// Chart data placeholders
let chartData = {
  matchedCustomers: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Matched Customers",
            data: values,
          },
        ],
      };
    }
  }
};

// Query: Total Customers
async function getTotalCustomers(account, startDate, endDate) {
  console.log("getTotalCustomers: ", account);

  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Get all current customers
  let customersQuery = {
    "events": [
          {
              "id": "Customer Activity",
              "math": "dau",
              "name": "Customer Activity",
              "type": "events",
              "order": 0,
              "properties": [
                  {
                    "key": `properties.customer.version = '${account.active && account.active.models && account.active.models.customers ? account.active.models.customers.version : '0.9.0'}'`,
                    "type": "hogql",
                    "value": null
                  },
                  {
                      "key": "clientId",
                      "type": "event",
                      "value": [
                          `${account.active.id}`
                      ],
                      "operator": "exact"
                  },
                  {
                      "key": "type",
                      "type": "event",
                      "value": [
                          `Customer Visit`
                      ],
                      "operator": "exact"
                  }
              ]
          }
      ],
      "date_to": null,
      "display": "ActionsTable",
      "insight": "TRENDS",
      "interval": "month",
      "date_from": "-60d",
      "entity_type": "events",
      //"refresh": (moment().minutes() < 10) // Refresh if before 10 minutes of new hour
  };

  // Execute query
  let customersResponse = null;
  try {
    // Query
    customersResponse = await axios.post(eventsApiUrl, customersQuery, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.config.events.api}`
      }
    });
  } catch (error) {
    console.log("ERROR: ", error);
    return;
  }
  

  // Parse customer count
  const totalCustomers = (customersResponse.data.result && customersResponse.data.result.length > 0) ? customersResponse.data.result[0].aggregated_value : 0;

  // Get all current customers
  let newCustomersQuery = {
    "events": [
        {
            "id": "Customer Activity",
            "math": "dau",
            "name": "Customer Activity",
            "type": "events",
            "order": 0,
            "properties": [
                {
                    "key": `properties.customer.version = '${account.active && account.active.models && account.active.models.customers ? account.active.models.customers.version : '0.9.0'}'`,
                    "type": "hogql",
                    "value": null
                },
                {
                    "key": "clientId",
                    "type": "event",
                    "value": [
                        `${account.active.id}`
                    ],
                    "operator": "exact"
                },
                {
                    "key": "type",
                    "type": "event",
                    "value": [
                        `Customer Visit`
                    ],
                    "operator": "exact"
                }
            ]
        }
    ],
    "date_to": null,
    "display": "ActionsTable",
    "interval": "day",
    "date_from": "-30d",
    "entity_type": "events",
    //"refresh": (moment().minutes() < 10) // Refresh if before 10 minutes of new hour
  };

  // Execute query
  const newCustomersResponse = await axios.post(eventsApiUrl, newCustomersQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Parse new customres
  const newCustomers = (newCustomersResponse.data.result && newCustomersResponse.data.result.length > 0) ? newCustomersResponse.data.result[0].aggregated_value : 0;

  // Determine delta
  const totalCustomersPrevious = totalCustomers - newCustomers;
  const customerDelta = (totalCustomersPrevious > 0) ? 100.0 * (totalCustomers - totalCustomersPrevious) / totalCustomersPrevious : 100.0;

  // Return values
  return {
    totalCustomers: totalCustomers,
    newCustomers: newCustomers,
    customerDelta: customerDelta
  };
}

// Query: Persona Activity
function getPersonaActivity(account, startDate, endDate, callback) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;
  console.log("Get Persona Activity: ", startDate, endDate);

  // Define query data
  let query = {
    "date_from": startDate,
    "date_to": endDate,
    "events": [
        {
            "id": "Customer Activity",
            "math": "dau",
            "name": "Customer Activity",
            "type": "events",
            "order": 0,
            "properties": [
                {
                    "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
                    "type": "hogql",
                    "value": null
                },
                {
                    "key": "clientId",
                    "type": "event",
                    "value": [
                        `${account.active.id}`
                    ],
                    "operator": "exact"
                }
            ]
        }
    ],
    "interval": "day",
    "breakdown": "properties.persona.name",
    "entity_type": "events",
    "breakdown_type": "hogql",
    //"refresh": (moment().minutes() < 1) // Refresh if before 10 minutes of new hour
  }

  // Execute query
  axios.post(eventsApiUrl, query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.config.events.api}`
      }
    }).then(res => {
        callback(res.data)
    })
    .catch((err) => {
        console.log(`ERROR: ${err}`);
    });
}

// Query: Customer Activity by Persona
async function getPersonas(account, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Get persona stats
  let personasQuery = {
    "events": [
      {
        "id": "Customer Activity",
        "math": "dau",
        "name": "Customer Activity",
        "type": "events",
        "order": 0,
        "custom_name": "Total Customer Created",
        "properties": [
            {
                "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
                "type": "hogql",
                "value": null
            },
            {
                "key": "clientId",
                "type": "event",
                "value": [
                    `${account.active.id}`
                ],
                "operator": "exact"
            }
        ]
      }
    ],
    "date_to": null,
    "display": "ActionsTable",
    "insight": "TRENDS",
    "interval": "month",
    "breakdown": "properties.persona.ref.id",
    "date_from": "all",
    "entity_type": "event",
    "breakdown_type": "hogql",
    //"refresh": (moment().minutes() < 10) // Refresh if before 10 minutes of new hour
  }

  // Execute query
  let personasResponse = null;
  try {
    // Query
    personasResponse = await axios.post(eventsApiUrl, personasQuery, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.config.events.api}`
      }
    });
  } catch (error) {
    console.log("ERROR: ", error);
    return;
  }

  // Parse persona data
  let personaData = [];
  if(personasResponse.data && personasResponse.data.result.length > 0) {
    for(var i = 0; i < personasResponse.data.result.length; i++) {
      // Extract persona
      personaData.push({
        "id": personasResponse.data.result[i].breakdown_value,
        "total": personasResponse.data.result[i].aggregated_value
      });
    }
  }

  // Return values
  return {
    personas: personaData
  };
}

function Home({ profile, account, knowledge, insights, report, model, dispatch }) {

  // Default states
  const [activeNav, setActiveNav] = React.useState(1);
  const [activeChartData, setActiveChartData] = React.useState("default");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setActiveChartData(activeChartData === "30-day" ? "90-day" : "30-day");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // Get history
  const history = useHistory();

  // Customer / Persona Data
  const [ totalCustomers, setTotalCustomers ] = React.useState(null);
  const [ customerGrowth, setCustomerGrowth ] = React.useState(null);
  const [ personaActivity, setPersonaActivity ] = React.useState([]);

  // Load customer stats
  React.useEffect(() => {
    if (account.initialized && account.active) {
      // Calcualte target dates based off selection
      const lookback = (activeChartData === "30-day" || activeChartData === "default") ? 60 : 180;
      const startDate = moment().subtract(lookback, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');

      // Customers: Update
      (async () => {
        // Get customer details
        const customerData = await getTotalCustomers(account, startDate, endDate);
        console.log("Customer Data: ", customerData);

        // Set customer data
        if(customerData) {
          setTotalCustomers(customerData.totalCustomers);
          setCustomerGrowth(customerData.customerDelta);
        }
      })(); 
    }
  }, [ account.active ]);

  // Load persona data
  React.useEffect(() => {
    if (account.active && knowledge.entities && knowledge.entities.length > 0) {
      console.log("Loading Persona Activity: ", knowledge.entities);

      // Calcualte target dates based off selection
      const lookback = activeChartData === "30-day" ? 60 : 180;
      const startDate = moment().subtract(lookback, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');

      // Sync Persona match data
      (async () => {
        // Get customer details
        const personaData = await getPersonas(account, startDate, endDate);

        // Update Personas
        if(personaData && personaData.personas) {
          // Clone existing personas
          let existingPersonas = knowledge.entities;

          // Iterate over updated persona data
          let updatedPersonas = [];
          for(var i = 0; i < personaData.personas.length; i++) {
            // Find matching persona
            const matchedPersona = _.find(existingPersonas, function (existingPersona) {
              return existingPersona.id == personaData.personas[i].id;
            });

            // Update if matched
            if(matchedPersona) {
              // Update match count
              matchedPersona.matched = personaData.personas[i].total;

              // Add to new personas
              updatedPersonas.push(matchedPersona);
            }
          }
        }
      })();

      // Graph: Persona Activity
      getPersonaActivity(account, startDate, endDate, function(data) {
        console.log("Persona Activity: ", data);

        // Format data
        let updatedPersonaActivities = [];
        for(var i = 0; i < data.result.length; i++) {
          // Get value
          let personaName = data.result[i].breakdown_value;

          // Calculate ranges
          let total30DayTarget = _.sum(data.result[i].data.slice(Math.max(data.result[i].data.length - 30, 0)));
          let total30DayPrevious = _.sum(data.result[i].data.slice(Math.max(data.result[i].data.length - 60, 0), Math.max(data.result[i].data.length - 30, 0)));

          // Fine matching Persona
          let matchedPersona = _.find(knowledge.entities, {
            name: personaName
          });

          // Setup values
          updatedPersonaActivities.push({
            id: matchedPersona ? matchedPersona.id : '',
            name: personaName,
            matches: total30DayTarget,
            delta: (total30DayPrevious > 0) ? 100.0 * (total30DayTarget - total30DayPrevious) / total30DayPrevious : 100.0
          });
        }

        // Set new persona
        setPersonaActivity(updatedPersonaActivities);
      });
    }
  }, [ account.active, knowledge.entities ]);

  // Query iunput
  const [query, setQuery] = React.useState("");

  // Handle text query change
  const handleChange = (e) => {
    // Set query
    setQuery(e.target.value);
  }

  // Handle artwork selection
  const selectEntity = (entity) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: entity
    });

    // Got to review page
    history.push(`/admin/personas/view/${entity.id}`);
  }

  return (
    <>
      <ProfileHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Link to="/admin/customers/personas">
              <Card
                className="bg-gradient-primary border-0"
                style={{ cursor: 'pointer', height: 140 }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        className="text-uppercase text-muted mb-0 text-white"
                        tag="h5"
                      >
                        Active Customers
                      </CardTitle>
                      { totalCustomers ?
                        <span className="h1 font-weight-bold mb-0 text-white">
                          { accounting.formatNumber(totalCustomers, { precision: 0 }) }
                        </span>
                        :
                        <ThreeDots
                          visible={true}
                          height="50"
                          width="50"
                          color="#ffffff"
                          radius="6"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      }
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="ni ni-single-02" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-1 mb-0 text-sm">
                    { customerGrowth ? 
                      <>
                        <span className="text-white mr-2">
                          <i className="fa fa-arrow-up" /> { accounting.formatNumber(customerGrowth, { precision: 0 }) }%
                        </span>{" "}
                        <span className="text-info text-nowrap">(30 Days)</span>
                      </>
                      :
                      <></>
                    }
                  </p>
                </CardBody>
              </Card>
            </Link>
          </Col>

          <Col xl="8">
            <Card
              className="bg-gradient-primary border-0"
              style={{
                height: 140
              }}
            >
              <CardBody>
                <Row>
                  <Col md="12">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Ask a question about your customers..."
                      style={{
                        zIndex: 100000000,
                        position: 'absolute',
                        width: '90%',
                        top: 5
                      }}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Set persona
                          dispatch({
                            type: SET_PERSONA,
                            payload: {
                              persona: masterPersona
                            }
                          });

                          // Set initial query
                          if(query.length > 0) {
                            dispatch({
                              type: SET_QUERY,
                              payload: {
                                query: query
                              }
                            });
                          }

                          // Go to agent
                          history.push(`/admin/knowledge/agent`);
                        }
                      }}
                      disabled
                    />
                    <div
                      className="icon icon-shape bg-white text-dark rounded-circle shadow"
                      style={{
                        zIndex: 200000000,
                        position: 'absolute',
                        right: '2%',
                        top: -2,
                        height: 80,
                        width: 80
                      }}
                    >
                      <i className="ni ni-books" />
                    </div>
                  </Col>
                  <Col
                    md="12"
                    style={{
                      paddingTop: 65
                    }}
                  >
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Freshness Guarantee",
                        description: "their commitment to providing premium, fresh Maine lobsters directly to your doorstep, ensuring the highest quality and taste"
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      freshness guarantee
                    </Button>
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Source and Sustainability",
                        description: "their dedication to sourcing authentic Maine lobsters sustainably and supporting local fishermen and communities in the process"
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      source and sustainability
                    </Button>
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Packaging and Presentation",
                        description: "their ability to ensure that our lobsters are delivered in high-quality packaging, maintaining their freshness and presentation throughout the delivery process."
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      packaging and presentation
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="8">
            <Card>
              <CardHeader className="border-0">
                <div className="col">
                  <h6 className="surtitle">Retention</h6>
                  <h3 className="mb-0">Customer Personas</h3>
                </div>
                <div className="col">
                  
                </div>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col" style={{
                      maxWidth: 200
                    }}>
                      Name
                    </th>
                    <th className="sort" data-sort="matched" scope="col">
                      Customers
                    </th>
                    <th className="sort" data-sort="value" scope="col">
                      Lifetime Value
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  { knowledge.entities.map((entity, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              { entity.name }
                            </a>
                          </h4>
                          <small>

                          </small>
                        </div>
                      </th>
                      <td className="matched text-center">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              { (entity.matched) ? entity.matched : 0 }
                            </a>
                          </h4>
                        </div>
                      </td>
                      <td className="value text-center">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              { entity.value }
                            </a>
                          </h4>
                        </div>
                      </td>
                      <td className="text-right">
                        {/* <Button
                          color="secondary" size="md" type="button"
                          onClick={() => {
                            // Send to customer view
                            history.push(`/admin/customers/insights?personaId=${entity.id}`);
                          }}
                          style={{
                            width: 150
                          }}
                        >
                          <span className="btn-inner--icon mr-3">
                            <i className="ni ni-single-02" />
                          </span>
                          <span className="btn-inner--text">Matches</span>
                        </Button> */}
                        <Button
                          color="primary" size="md" type="button"
                          onClick={() => {
                            // select entity
                            selectEntity(entity);
                          }}
                          style={{
                            width: 180
                          }}
                        >
                          <span className="btn-inner--icon mr-3">
                            <i className="ni ni-badge" />
                          </span>
                          <span className="btn-inner--text">View Profile</span>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className="py-4">

              </CardFooter>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="surtitle">Acquisition</h6>
                    <h5 className="h3 mb-0">New Customers</h5>
                  </div>
                  <div className="col">
                    <Link to="/admin/customers/personas">
                      <Button className="float-right" color="secondary" size="sm" type="button">
                        View All
                      </Button>
                    </Link>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <ListGroup className="list my--3" flush>
                  { personaActivity.slice(0, 4).map((persona, index) => (
                    <ListGroupItem className="px-0" key={index}>
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="ni ni-circle-08" />
                          </div>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              { persona.name }
                            </a>
                          </h4>
                          <small>
                            <a href="#">{ persona.matches } Recent Customers</a>
                          </small>
                          <br/>
                          <p className="mt-0 mb-0 text-sm">
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up" /> { accounting.formatNumber(persona.delta, { precision: 0 }) }%
                            </span>{" "}
                            <span className="text-nowrap">Since last month</span>
                          </p>
                        </div>
                        <Col className="col-auto">
                          <Button
                            color="primary" size="sm" type="button"
                            onClick={() => {
                              // Send to customer view
                              history.push(`/admin/customers/insights?personaId=${persona.id}`);
                            }}
                          >
                            View
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedHome = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(Home);

export default ConnectedHome;
