// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,

    // Campaigns
    selectedCampaign: null,

    // Customers
    selectedCustomer: null,
    customers: [],

    // Reports
    reports: [
      {
        query: "paid",
        name: "Paid Social",
        description: "Customers that were influenced by paid social campaigns.",
        active: true
      }
    ],
    filters: {
      timeframe: {
        startDate: "2022-11-01",
        endDate: "2022-11-30"
      },
      clients: {
        list: [],
        selected: null
      }
    },

    // Customer Demographics
    demographics: {
      age: 35.0,
      gender: {
        male: 50.0,
        female: 50.0
      }
    },

    // Customer Preferences
    preferences: []
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const insightsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_INSIGHTS: {
          console.log("Initializing insights...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              filters: action.payload.filters
          };
        }
        case actionTypes.SET_ACTIVE_CAMPAIGN: {
          console.log("Selected Campaign: ", action.payload);

          // Update state
          return {
              ...state,
              selectedCampaign: action.payload
          };
        }
        case actionTypes.SET_CUSTOMERS: {
          console.log("Setting Customers: ", action.payload);

          // Update state
          return {
              ...state,
              customers: action.payload
          };
        }
        case actionTypes.SET_CUSTOMERS_DEMOGRAPHICS: {
          console.log("Setting Customers Demographics: ", action.payload);

          // Update state
          return {
              ...state,
              demographics: action.payload
          };
        }
        case actionTypes.SET_CUSTOMERS_AFFINITIES: {
          console.log("Setting Customers Affinities: ", action.payload);

          // Update state
          return {
              ...state,
              preferences: action.payload
          };
        }
        case actionTypes.SET_ACTIVE_CUSTOMER: {
          console.log("Selected Customer: ", action.payload);

          // Update state
          return {
              ...state,
              selectedCustomer: action.payload
          };
        }
        case actionTypes.SET_ACTIVE_CUSTOMER_ACTIVITY: {
          console.log("Setting Customer Activity: ", action.payload);

          // Get current customer
          let selectedCustomer = state.selectedCustomer;

          // Set activity
          selectedCustomer.data.activity = {
            enabled: true,
            history: action.payload
          };

          // Update state
          return {
              ...state,
              selectedCustomer: selectedCustomer
          };
        }
        default:
            return state;
    }
};

export default insightsReducer;


/*

reports: [
  {
    query: "paid",
    name: "Paid Social",
    description: "Customers that were influenced by paid social campaigns.",
    active: true
  },
  {
    query: "email",
    name: "Email",
    description: "Customers that engage with marketing emails.",
    active: false
  },
  {
    query: "organic",
    name: "Organic",
    description: "Customers coming from organic sources such as Google.",
    active: false
  },
  {
    query: "referral",
    name: "Referral",
    description: "Customers that were referred by external sources.",
    active: false
  },
  {
    query: "direct",
    name: "Direct",
    description: "Customers that visited the site directly on their own.",
    active: false
  }
],

*/
