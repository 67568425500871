import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
  Button,
  Progress,
  Tabs,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Bar } from 'react-chartjs-2';
import accounting from 'accounting-js';

// import styles
import "./styles.scss";

const RichInsights = ({ data, persona, title }) => {
  const [activeTab, setActiveTab] = useState('demographics');

  const demographicsData = {
    labels: ['18-24', '25-34', '35-44', '45-54', '55+'],
    datasets: [
      {
        label: 'RPV by Age',
        data: data?.demographics?.rpvByAge || [120, 190, 230, 180, 150],
        backgroundColor: 'rgba(66, 153, 225, 0.5)',
      }
    ]
  };

  const velocityData = {
    labels: ['0-30 days', '31-60 days', '61-90 days', '90+ days'],
    datasets: [
      {
        label: 'Purchase Velocity',
        data: data?.velocity?.purchases || [45, 30, 15, 10],
        backgroundColor: 'rgba(72, 187, 120, 0.5)',
      }
    ]
  };

  const generateAIExplanation = () => {
    // This would be connected to your AI service
    return {
      summary: "This persona shows strong engagement in the premium product category with a 30% higher average order value compared to other segments. The data suggests they are value-oriented customers who prefer quality over price.",
      insights: [
        "Higher purchase frequency in the 31-60 day range indicates regular grooming routine",
        "Premium product preference aligns with professional background",
        "Age distribution suggests established career professionals"
      ]
    };
  };

  const aiExplanation = generateAIExplanation();

  return (
    <Card 
      className="rich-insights"
      style={{
        minHeight: 500
      }}
    >
      <CardHeader>
        <h3 className="mb-0">{title || "Rich Insights"}</h3>
        <p className="text-muted mb-0">AI-powered analysis for {persona?.name || "selected segment"}</p>
      </CardHeader>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === 'demographics' ? 'active' : ''}
              onClick={() => setActiveTab('demographics')}
            >
              Demographics
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 'velocity' ? 'active' : ''}
              onClick={() => setActiveTab('velocity')}
            >
              Purchase Velocity
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 'explanation' ? 'active' : ''}
              onClick={() => setActiveTab('explanation')}
            >
              AI Analysis
            </NavLink>
          </NavItem>
        </Nav>

        <div className="tab-content mt-4">
          <div className={`tab-pane ${activeTab === 'demographics' ? 'active' : ''}`}>
            <Row>
              <Col md="8">
                <Bar data={demographicsData} options={{
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      text: 'Revenue Per Visit by Age Group'
                    }
                  }
                }} />
              </Col>
              <Col md="4">
                <div className="demographics-stats">
                  <h4>Key Metrics</h4>
                  <div className="stat-item">
                    <label>Avg. Order Value</label>
                    <h3>{accounting.formatMoney(data?.demographics?.avgOrderValue || 85)}</h3>
                  </div>
                  <div className="stat-item">
                    <label>Gender Distribution</label>
                    <Progress multi>
                      <Progress bar color="info" value={data?.demographics?.genderSplit?.female || 65}>
                        Female {data?.demographics?.genderSplit?.female || 65}%
                      </Progress>
                      <Progress bar color="success" value={data?.demographics?.genderSplit?.male || 35}>
                        Male {data?.demographics?.genderSplit?.male || 35}%
                      </Progress>
                    </Progress>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className={`tab-pane ${activeTab === 'velocity' ? 'active' : ''}`}>
            <Row>
              <Col md="8">
                <Bar data={velocityData} options={{
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      text: 'Purchase Velocity Distribution'
                    }
                  }
                }} />
              </Col>
              <Col md="4">
                <div className="velocity-stats">
                  <h4>Velocity Metrics</h4>
                  <div className="stat-item">
                    <label>Avg. Time Between Purchases</label>
                    <h3>{data?.velocity?.avgTimeBetweenPurchases || "45"} days</h3>
                  </div>
                  <div className="stat-item">
                    <label>Repeat Purchase Rate</label>
                    <h3>{data?.velocity?.repeatPurchaseRate || "65"}%</h3>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className={`tab-pane ${activeTab === 'explanation' ? 'active' : ''}`}>
            <Row>
              <Col md="12">
                <div className="ai-explanation">
                  <div className="summary-section">
                    <h4>Key Findings</h4>
                    <p>{aiExplanation.summary}</p>
                  </div>
                  <div className="insights-section mt-4">
                    <h4>Detailed Insights</h4>
                    {aiExplanation.insights.map((insight, index) => (
                      <div key={index} className="insight-item">
                        <Badge color="primary" className="mr-2">{index + 1}</Badge>
                        {insight}
                      </div>
                    ))}
                  </div>
                  <div className="exploration-section mt-4">
                    <h4>Suggested Explorations</h4>
                    <Row>
                      <Col md="4">
                        <Button color="primary" outline block>
                          Analyze Product Affinity
                        </Button>
                      </Col>
                      <Col md="4">
                        <Button color="primary" outline block>
                          Compare to Other Segments
                        </Button>
                      </Col>
                      <Col md="4">
                        <Button color="primary" outline block>
                          View Time-based Trends
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default RichInsights;
