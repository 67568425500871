// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,

    entity: {
      name: "Persona 1",
      total: 100,
      value: "$2,500",
      strength: 80,
      factors: [],
      customers: [],
      dimensions: [],
      profile: {
          card: {
              gender: "Female",
              age: "25-55",
              location: "Urban, Metropolitan Areas",
              job: "Health Professionals, Fitness Coaches, Top-level Executives"
          },
          summary: {
              name: "Health-Savvy Seafood Lovers",
              headline: "Experience Freshness with Every Bite from Get Maine Lobster Delivered to Your Doorstep",
              details: "This purchaser is a health-conscious consumer who prioritizes fresh, natural, and nourishing food sources. They enjoy looking for the nutritional benefits of seafood and are likely to buy from Get Maine Lobster for the fresh and high-quality seafood selection it offers. This consumer cares deeply about sustainable fishing practices, appreciates the rapid delivery service, and values eco-friendly packaging for preserving freshness and reducing environmental impact."
          },
          kpis: [
              {
                  "name": "Average Lifetime Value",
                  "value": "$0.00"
              },
              {
                  "name": "Average Total Orders",
                  "value": "0.0"
              },
              {
                  "name": "Average Order Value",
                  "value": "$0.00"
              }
          ]
      },
      demographics: {
          age: 0,
          gender: {
              male: 50,
              female: 50
          },
          income: "$0.00"
      }
    },
    factors: [],
    stats: [],
    items: [],
    preferences: []
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const modelReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_MODEL: {
          console.log("Initializing model...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.SET_ACTIVE_ENTITY: {
          console.log("Setting active entity...", action.payload);

          // Update state
          return {
              ...state,
              entity: action.payload
          };
        }
        case actionTypes.SET_DIMENSIONS: {
          console.log("Setting persona dimensions...", action.payload);

          // Update state
          return {
              ...state,
              entity: {
                ...state.entity,
                dimensions: action.payload
              }
          };
        }
        case actionTypes.SET_PERSONA_AFFINITIES: {
          console.log("Setting Persona Affinities...", action.payload);

          // Update state
          return {
              ...state,
              preferences: action.payload
          };
        }
        default:
            return state;
    }
};

export default modelReducer;



/*

stats: [
  {
    id: "@axon/commerce/customers/age",
    name: "$100 - $200",
    value: 15,
    display: "LTV: $100 - $200"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$200 - $300",
    value: 30,
    display: "LTV: $200 - $300"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$300 - $500",
    value: 45,
    display: "LTV: $300 - $500"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$500 - $800",
    value: 10,
    display: "LTV: $500 - $800"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$800+",
    value: 5,
    display: "LTV: $800+"
  }
],

*/
