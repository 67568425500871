/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Label,
  ListGroup,
  ListGroupItem,
  Input,
  Progress,
  Table,
  Container,
  Spinner,
  Row,
  Col
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// Firestore
import { firestore } from "./../../../firebase";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_CUSTOMERS,
  SET_ACTIVE_REPORT,
  SET_ACTIVE_CUSTOMER
} from 'store/actions';

// Import report blocks
import CampaignsChart from "./../components/CampaignsChart.js";
import RichInsights from "components/RichInsights";

function ViewCampaign({ profile, account, insights, model, dispatch }) {
  // Get history
  const history = useHistory();

  // Handle customer selection
  const selectCustomer = async (customer) => {
    console.log("Selecting Customer: ", customer);

    // Load customer data
    firestore.collection("customers").doc(customer.document_id).get()
      .then((doc) => {
        if(doc.exists) {
          // Update customer
          const customerData = doc.data();

          // Update local store
          dispatch({
            type: SET_ACTIVE_CUSTOMER,
            payload: customerData
          });

          // Got to review page
          console.log(customerData);
          history.push(`/admin/customers/insights/view/${customer.document_id}`);
        }
      })
  }

  return (
    <>
      <KnowledgeHeader
        headline={(insights.selectedCampaign && insights.selectedCampaign.campaign_name) ? insights.selectedCampaign.campaign_name : 'Campaign Details'}
        description=""
      />
      <Container className="mt--6" fluid>
        <CampaignsChart campaign={insights.selectedCampaign} />
        <Row>
          <Col xl="4">
            <Card>
              <CardBody>
                <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                  <span>Customer Summary</span>
                </CardTitle>
                {/* { insights.demographics.age ?
                  <Row>
                    <Col md="1"></Col>
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Age
                    </Label>
                    <Col md="6">
                      <Row className="col-form-label">
                        <div>{insights.demographics.age.toFixed(0)}</div>
                      </Row>
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  :
                  <></>
                } */}
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Gender
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>{insights.demographics.gender.female.toFixed(0)}% ∙ Female</div>
                    </Row>
                    <Row className="col-form-label">
                      <div>{insights.demographics.gender.male.toFixed(0)}% ∙ Male</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="bg-transparent">
                <Form>
                  <Select2
                    className="form-control"
                    defaultValue="1"
                    options={{
                      placeholder: "Select",
                    }}
                    data={[
                      { id: "1", text: "Top Performing Ads" }
                    ]}
                  />
                </Form>
              </CardHeader>
              <CardBody>
                <ListGroup className="list my--3" flush>
                  { insights.preferences.map((concept, index) => (
                    <ListGroupItem className="px-0" key={index}>
                      <Row className="align-items-center">
                        <div className="col">
                          <h5>
                            <Badge color="primary" className="mr-2">
                              {concept.type}
                            </Badge>
                            {concept.display}
                          </h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="primary"
                            max="100"
                            value={concept.value}
                          />
                        </div>
                        <Col className="col-auto">
                          <Button
                            color="primary" size="sm" type="button"
                            style={{
                              width: 100
                            }}
                            onClick={() => {
                              // View customer activity drill-down
                              //history.push(`/admin/customers/insights?persona=${model.entity.name}&filter=${dimension.display}`);
                            }}
                            disabled
                          >
                            View
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xl="8">
            <Row>
              <Col xl="12">
                <Card
                  className="bg-gradient-primary border-0"
                  style={{
                    height: 140
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Search customers activity..."
                          style={{
                            zIndex: 100000000,
                            position: 'absolute',
                            width: '90%',
                            top: 5
                          }}
                          onChange={() => { console.log("changing...")}}
                          disabled
                        />
                        <div
                          className="icon icon-shape bg-white text-dark rounded-circle shadow"
                          style={{
                            zIndex: 200000000,
                            position: 'absolute',
                            right: '2%',
                            top: -2,
                            height: 80,
                            width: 80
                          }}
                        >
                          <i className="ni ni-books" />
                        </div>
                      </Col>
                      <Col
                        md="12"
                        style={{
                          paddingTop: 65
                        }}
                      >
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          first time purchasers
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          purchased on visit
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          multiple visits
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <div className="col">
                <RichInsights 
                  data={{
                    demographics: {
                      rpvByAge: insights.demographics.age ? [insights.demographics.age] : undefined,
                      gender: insights.demographics.gender
                    },
                    velocity: insights.preferences
                  }}
                  title="Customer Insights"
                  persona={insights.selectedCampaign}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewCampaign = connect(state => ({
  profile: state.profile,
  account: state.account,
  insights: state.insights,
  model: state.model
}))(ViewCampaign);

export default ConnectedViewCampaign;
