/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Imports
import Sample from "views/pages/Sample.js";

// Auth
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

// Personas (Core)
import Home from "views/personas/home";
import PersonaModel from "views/personas/view";
import PersonaAgent from "views/personas/agent";

// Customers
import CustomerAcquisition from "views/customers/acquisition";
// import Customers from "views/customers/insights";
// import ViewCustomer from "views/customers/insights/view";
// import Behaviors from "views/customers/behaviors";
import CustomerSegments from "views/customers/segments";
// import SegmentModel from "views/customers/segments/view";

// Capaigns
import Campaigns from "views/campaigns";
import ViewCampaign from "views/campaigns/view";

// Settings
import Clients from "views/settings/clients";

const routes = [
  {
    collapse: true,
    name: "Personas",
    display: true,
    icon: "ni ni-badge text-primary",
    state: "personasCollapse",
    views: [
      {
        path: "/home",
        display: true,
        name: "Home",
        miniName: "H",
        component: Home,
        layout: "/admin",
      },
      {
        path: "/personas/view/:id",
        display: false,
        component: PersonaModel,
        layout: "/admin",
      },
      {
        path: "/personas/chat",
        display: true,
        name: "Chat",
        miniName: "C",
        component: PersonaAgent,
        layout: "/admin",
      }
    ],
  },

  {
    collapse: true,
    name: "Customers",
    display: true,
    icon: "ni ni-single-02 text-primary",
    state: "customersCollapse",
    views: [
      // {
      //   path: "/customers/view/:query",
      //   display: false,
      //   component: ViewCampaign,
      //   layout: "/admin",
      // },
      {
        path: "/customers/acquisition",
        display: true,
        name: "Acquisition",
        miniName: "A",
        component: CustomerAcquisition,
        layout: "/admin",
      },
      // {
      //   path: "/customers/segments",
      //   display: true,
      //   name: "Segments",
      //   miniName: "S",
      //   component: CustomerSegments,
      //   layout: "/admin",
      // }
    ],
  },

  {
    collapse: true,
    name: "Campaigns",
    display: true,
    icon: "ni ni-money-coins text-primary",
    state: "insightsCollapse",
    access: "campaigns",
    views: [
      {
        path: "/campaigns/view/:query",
        display: false,
        component: ViewCampaign,
        layout: "/admin",
      },
      {
        path: "/campaigns",
        display: true,
        name: "Performance",
        miniName: "P",
        component: Campaigns,
        layout: "/admin",
      }
    ],
  },

  {
    collapse: true,
    name: "Examples",
    display: false,
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        display: false,
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        display: false,
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      }
    ],
  },
];

export default routes;


/*

  {
    collapse: true,
    name: "Personas",
    display: true,
    icon: "ni ni-single-02 text-primary",
    state: "personasCollapse",
    views: [
      {
        path: "/home",
        display: true,
        name: "Home",
        miniName: "H",
        component: Home,
        layout: "/admin",
      },
      {
        path: "/personas/view/:id",
        display: false,
        component: PersonaModel,
        layout: "/admin",
      },
      
      // {
      //   path: "/customers/insights/view/:id",
      //   display: false,
      //   component: ViewCustomer,
      //   layout: "/admin",
      // },
      // {
      //   path: "/customers/insights",
      //   display: true,
      //   name: "Cohorts",
      //   miniName: "C",
      //   component: Customers,
      //   layout: "/admin",
      // },
      // {
      //   path: "/knowledge/agent",
      //   display: false,
      //   name: "Agent",
      //   miniName: "A",
      //   component: Agent,
      //   layout: "/admin",
      // },
      // {
      //   path: "/knowledge/behaviors",
      //   display: true,
      //   name: "Behaviors",
      //   miniName: "B",
      //   component: Behaviors,
      //   layout: "/admin",
      // },
      // {
      //   path: "/knowledge/segments/view/:id",
      //   display: false,
      //   component: SegmentModel,
      //   layout: "/admin",
      // },
      // {
      //   path: "/knowledge/segments",
      //   display: true,
      //   name: "Segments",
      //   miniName: "S",
      //   component: Segments,
      //   layout: "/admin",
      // },
      {
        path: "/knowledge/agent",
        display: false,
        name: "Agent",
        miniName: "A",
        component: Agent,
        layout: "/admin",
      }
    ],
  },

    // {
  //   collapse: true,
  //   name: "Knowledge",
  //   display: true,
  //   icon: "ni ni-books text-primary",
  //   state: "appsCollapse",
  //   views: [
  //     // {
  //     //   path: "/knowledge/behavior",
  //     //   display: true,
  //     //   name: "Behavior",
  //     //   miniName: "B",
  //     //   component: Sample,
  //     //   layout: "/admin",
  //     // },
  //     {
  //       path: "/knowledge/research",
  //       display: true,
  //       name: "Research",
  //       miniName: "R",
  //       component: Research,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/knowledge/agent",
  //       display: false,
  //       name: "Agent",
  //       miniName: "A",
  //       component: Agent,
  //       layout: "/admin",
  //     }
  //   ],
  // },

  // {
  //   collapse: true,
  //   name: "Settings",
  //   display: true,
  //   icon: "ni ni-settings-gear-65 text-primary",
  //   state: "settingsCollapse",
  //   views: [
  //     // {
  //     //   path: "/apps/pixel",
  //     //   display: true,
  //     //   name: "Pixel",
  //     //   miniName: "P",
  //     //   component: Sample,
  //     //   layout: "/admin",
  //     // },
  //     // {
  //     //   path: "/knowledge/clients",
  //     //   display: true,
  //     //   name: "Clients",
  //     //   miniName: "C",
  //     //   component: Clients,
  //     //   layout: "/admin",
  //     // },
  //     {
  //       path: "/account",
  //       display: true,
  //       name: "Account",
  //       miniName: "A",
  //       component: Sample,
  //       layout: "/admin",
  //     }
  //   ],
  // },

*/