// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// action - account
export const INITIALIZE_ACCOUNT = '@account/INITIALIZE_ACCOUNT';
export const UPDATE_ACCOUNT = '@account/UPDATE_ACCOUNT';

// action - profile
export const INITIALIZE_PROFILE = '@profile/INITIALIZE_PROFILE';
export const CLEAR_PROFILE = '@profile/CLEAR_PROFILE';
export const UPDATE_PROFILE_USER = '@profile/UPDATE_PROFILE_USER';
export const UPDATE_WELCOME_STAGE = '@profile/UPDATE_WELCOME_STAGE';

/*
 *  Peronsa AI
 */

// action - agent
export const INITIALIZE_AGENT = '@personas/agent/INITIALIZE_AGENT';
export const SET_PROCESSING = '@personas/agent/SET_PROCESSING';
export const SET_QUERY = '@personas/agent/SET_QUERY';
export const SET_PERSONA = '@personas/agent/SET_PERSONA';
export const CLEAR_MESSAGES = '@personas/agent/CLEAR_MESSAGES';
export const ADD_CHAT_MESSAGE = '@personas/agent/ADD_CHAT_MESSAGE';

// action - profile
export const SET_PERSONA_AFFINITIES = '@personas/profile/SET_PERSONA_AFFINITIES';

/*
 *  Customers
 */

 // action - personas
 export const INIITIALIZE_PERSONAS = '@customers/personas/INIITIALIZE_PERSONAS';
 export const SET_PERSONAS = '@customers/personas/SET_PERSONAS';
 export const INIITIALIZE_MODEL = '@customers/personas/INIITIALIZE_MODEL';
 export const SET_FACTORS = '@customers/personas/SET_FACTORS';
 export const SET_DIMENSIONS = '@customers/personas/SET_DIMENSIONS';
 export const SET_ACTIVE_ENTITY = '@customers/personas/SET_ACTIVE_ENTITY';

 // action - segments
 export const SET_SEGMENT_STRATEGY = '@customers/segments/SET_SEGMENT_STRATEGY';

// action - insights
export const INIITIALIZE_INSIGHTS = '@customers/insights/INIITIALIZE_INSIGHTS';
export const SET_CUSTOMERS = '@customers/insights/SET_CUSTOMERS';
export const SET_CUSTOMERS_DEMOGRAPHICS = '@customers/insights/SET_CUSTOMER_DEMOGRAPHICS';
export const SET_CUSTOMERS_AFFINITIES = '@customers/insights/SET_CUSTOMERS_AFFINITIES';
export const SET_ACTIVE_CUSTOMER = '@customers/insights/SET_ACTIVE_CUSTOMER';
export const SET_ACTIVE_CUSTOMER_ACTIVITY = '@customers/insights/SET_ACTIVE_CUSTOMER_ACTIVITY';



/*
 *  Knowledge
 */

// action - domains
export const INIITIALIZE_KNOWLEDGE = '@knowledge/INIITIALIZE_KNOWLEDGE';
export const SET_ACTIVE_DOMAIN = '@knowledge/domains/SET_ACTIVE_DOMAIN';

// action - research
export const INITIALIZE_RESEARCH = '@knowledge/research/INITIALIZE_RESEARCH';
export const SET_ACTIVITIES = '@knowledge/research/SET_ACTIVITIES';

/*
 *  Campaign Insights
 */

// action - campaigns
export const INIITIALIZE_REPORT = '@campaigns/INIITIALIZE_REPORT';
export const SET_ACTIVE_REPORT = '@campaigns/SET_ACTIVE_REPORT';
export const SET_FILTER_TIMEFRAME = '@campaigns/SET_FILTER_TIMEFRAME';
export const SET_READOUT = '@campaigns/SET_READOUT';
export const SET_ACTIVE_CAMPAIGN = '@campaigns/SET_ACTIVE_CAMPAIGN';