// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    attempts: 0,

    // User behaviors
    selectedStrategy: {
        name: "Accelerate Purchase Journey",
        id: "@axon/commerce/customers",
        display: "3 Segments",
    },
    
    // Segmentation Strategies
    strategies: [
     {
        name: "Accelerate Purchase Journey",
        id: "@axon/commerce/customers",
        display: "3 Segments",
      },
      {
        name: "Increase Purchase Frequency",
        id: "@axon/marketing/campaigns",
        display: "0 Segments"
      },
      {
        name: "Customer Re-Engagement",
        id: "@axon/marketing/campaigns",
        display: "0 Segments"
      }
    ],

    // Segmentation Domains
    list: [
      {
        id: "segment-1",
        name: "Established Business Professionals",
        total: 58,
        value: "$327,493.93",
        strength: 80,
        description: "Our target customer segment is composed of tech-savvy, stylish, and socially conscious individuals between the ages of 40 and 60. This group is primarily composed of lawyers, C-level executives, and business owners who prioritize style, comfort, and functionality when making a purchase. They are looking for a versatile shirt that can be worn to the office or on a night out"
      }
    ]
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const segmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SEGMENT_STRATEGY: {
          console.log("Setting target segmentation strategy...", action.payload);

          // Update state
          return {
              ...state,
              selectedStrategy: action.payload
          };
        }
        default:
            return state;
    }
};

export default segmentsReducer;
